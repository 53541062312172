import {Box, Button, Dialog, TextField, Typography} from "@mui/material";
import React, { useState, useEffect} from 'react';
import API from "../api";
import * as yup from "yup";
import { useFormik } from "formik";

const style = {
    top: '50%',
    left: '50%',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ChangePasswordForm = ({handleClose}) => {
    const validationSchema = yup.object({
        currPass: yup.string()
            .required("Current password is required"),
        newPass: yup.string()
            .required('New password is required')
            .notOneOf([yup.ref('currPass')], 'New password cannot be equal to current password')
            .matches(/^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1})/, 'Should include at least 1 digit, 1 uppercase, 1 lowercase'),
    });

    const formik = useFormik({
        initialValues: {
            currPass: '',
            newPass: ''
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const { currPass, newPass } = values;
                const reqBody = {
                    newPassword: newPass,
                    currentPassword: currPass
                };
                await API.post('/updatePassword', reqBody, { withCredentials: true });
                setSubmitting(false);
                handleClose();
            } catch (e) {
                setErrors({'general': e.data || e.message});
            }
        }
    });

    return (

        <Box sx={style}>
            <form onSubmit={formik.handleSubmit} style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                padding: '20px'
            }}>
                <Typography variant='h6'>
                    Change Password
                </Typography>
                <TextField
                    name="currPass"
                    type="password"
                    label={"Current Password"}
                    value={formik.values.currPass}
                    onChange={formik.handleChange}
                    error={formik.touched.currPass && Boolean(formik.errors.currPass)}
                    helperText={formik.touched.currPass && formik.errors.currPass}
                    margin="normal"
                />
                <TextField
                    name="newPass"
                    type="password"
                    label={"New Password"}
                    value={formik.values.newPass}
                    onChange={formik.handleChange}
                    error={formik.touched.newPass && Boolean(formik.errors.newPass)}
                    helperText={formik.touched.newPass && formik.errors.newPass}
                    margin="normal"
                />
                {formik.errors.general && <span style={{color: 'red', display: 'block', margin: '10px 0 10px 0'}}>{formik.errors.general}</span>}
                <div style={{"display": "flex", "justifyContent": "flex-end"}}>
                    <Button type="submit" variant="contained" style={{margin: '5px'}}>Update</Button>
                    <Button variant="outlined" style={{margin: '5px'}} onClick={() => handleClose()}>Cancel</Button>
                </div>
            </form>
        </Box>
    );
}

export default ChangePasswordForm;
