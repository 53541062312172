import logo from '../logo.svg';
import '../App.css';

import React from "react";
import {
  BrowserRouter as Router,
    Switch,
} from "react-router-dom";

import history from '../utils/history';
import Login from '../pages/Login';
import UserInfo from '../pages/User';
import { ProvideAuth } from "../hooks/useAuth.js";
import PrivateRouteWrapper from '../components/PrivateRoute';
import PublicRouteWrapper from "./PublicRoute";
import ResetPassword from "../pages/ResetPassword"

export default function App() {
  return (
      <ProvideAuth>
      <Router history={history}>
        <div>
          <Switch>
            <PublicRouteWrapper path="/resetPassword/:id?">
              <ResetPassword />
            </PublicRouteWrapper>
            <PublicRouteWrapper path="/login">
              <Login />
            </PublicRouteWrapper>
            <PrivateRouteWrapper path="/user">
              <UserInfo />
            </PrivateRouteWrapper>
            <PrivateRouteWrapper path="/">
              <UserInfo />
            </PrivateRouteWrapper>
          </Switch>
        </div>
      </Router>
      </ProvideAuth>
  );
};
