import React, { useState, useEffect } from "react";
import { Box, TextField, Grid, Button, TextareaAutosize, Typography } from '@mui/material';
import * as yup from "yup";
import { useFormik } from 'formik';
import "../pages/styles.css";
import API from "../api";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

const style = {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: '20px'
}

function OfflineKey({closeDialog, licenseId}) {
    const [offlineKey, setOfflineKey] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    const descriptionElementRef = React.useRef(null);

    useEffect(() => {
        if (showDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [showDialog]);


    const validationSchema = yup.object({
        mid: yup.string()
            .min(10, 'Should be minimum 10 ')
            .max(40, 'Should be maximum 40')
            .required("MID is required")
            .matches(/^[a-zA-Z0-9]+$/, 'Should be alphanumeric'),
    });

    const formik = useFormik({
        initialValues: {
            mid: '',
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const response = await API.post('/updateOfflineKey', { mid: values.mid, licenseId }, { withCredentials: true });
                setOfflineKey(response.data.offlineKey);
                setSubmitting(false);
                setShowDialog(true);
            } catch (e) {
                setErrors({'general': e.data || e.message});
            }
        }
    });

    const handleClose = () => {
        setShowDialog(false);
    };

    const copyOfflineKeyToKeyboard = () => {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
    }

    return (
        <Box sx={style}>
            <form onSubmit={formik.handleSubmit} className="offlineKey" >
                <Typography variant='h6'>
                    Generate Offline Key
                </Typography>
                <TextField
                    name="mid"
                    label={"MID"}
                    value={formik.values.mid}
                    onChange={formik.handleChange}
                    error={formik.touched.mid && Boolean(formik.errors.mid)}
                    helperText={formik.touched.mid && formik.errors.mid}
                    margin="normal"
                    fullWidth={true}
                    style={{width: '350px'}}
                />
                {formik.errors.general && <span style={{color: 'red', display: 'block', margin: '10px 0 10px 0'}}>{formik.errors.general}</span>}
                <div style={{"display": "flex", "justifyContent": "flex-end"}}>
                    <Button variant="contained" type="submit" style={{margin: '5px'}}>
                        Generate
                    </Button>
                    <Button variant="outlined" style={{margin: '5px'}} onClick={() => {
                        closeDialog();
                    }}>
                        Cancel
                    </Button>
                </div>
            </form>
            <Dialog
                open={showDialog}
                onClose={handleClose}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                { document.queryCommandSupported('copy') &&
                <DialogActions>
                    <CopyToClipboard text={offlineKey}>
                    <Button onClick={copyOfflineKeyToKeyboard}>Copy to clipboard</Button>
                    </CopyToClipboard>
                </DialogActions>
                }

                <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                            style={{ wordWrap: "break-word" }}
                        >
                            {offlineKey}
                        </DialogContentText>
                </DialogContent>
            </Dialog>

        </Box>
    );
}

export default OfflineKey;
