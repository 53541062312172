import React, {useEffect, useState} from "react";
import {Paper, TextField, Grid, Button, Typography, Dialog} from '@mui/material';
import * as yup from "yup";
import { useHistory, useLocation, Redirect} from "react-router-dom";
import { useFormik } from 'formik';
import "./styles.css";
import API from "../api";

import { useAuth } from "../hooks/useAuth";
import ForgotPasswordForm from "../components/ForgotPasswordForm";


function ResetPassword() {
    const [isTokenValid, setValidToken] = useState(false);
    const [redirectToLogin, setRedirect] = useState(false);

    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    const regex = new RegExp("\\/resetPassword\\/([^\\/]+)", "g");
    const match = regex.exec(location.pathname)
    const token = match[1];

    useEffect(() => {
        API.get("/validateToken/" + token).then(response => {
            setValidToken(response.data.valid)
        }).catch(err => console.log(err));
    }, [])


    const validationSchema = yup.object({
        password: yup.string()
            .required("Password is required"),
        confirmPassword: yup.string()
            .required("Confirm Password")
            .oneOf([yup.ref('password'), null], 'Passwords must match')
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            const {password} = values;
            try {
                await API.post('/resetPassword/' + token, {password})
                setRedirect(true);
            } catch (e) {
                setErrors({'general': e.data || e.message});
            }
        }
    })

    //todo add redirects when token is expired (server-side should return appropriate error code)
    return redirectToLogin ? <Redirect to='/login'/> : isTokenValid ? (
        <div>
            <form onSubmit={formik.handleSubmit} className="loginForm">
                <Typography variant="h5" style={{marginTop: '15px', textAlign: 'center'}}>Reset Password</Typography>

                <TextField
                    name="password"
                    type="password"
                    label={"Password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    margin="normal"
                />
                <TextField
                    type="password"
                    name="confirmPassword"
                    label={"Confirm Password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    margin="normal"
                />
                {formik.errors.general && <span style={{color: 'red', display: 'block', margin: '10px 0 10px 0'}}>{formik.errors.general}</span>}
                <Button variant="outlined" type="submit">
                    Submit
                </Button>

            </form>

        </div>
    ) : <div style={{'text-align': 'center'}}>Token is invalid</div>
}

export default ResetPassword;
