import { useAuth } from "../hooks/useAuth";
import {
    Route,
    Redirect
} from "react-router-dom";
import {Breadcrumbs, Button, LinearProgress, Link, Typography} from "@material-ui/core";
import logo from '../resources/logo.svg'


function BasicNavigation() {
    let { signout } = useAuth();

    return (
        <div role="presentation" style={{margin: "16px"}}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" href="/">
                    <img alt="Sisense" src={logo} style={{width: '200px', height: 'auto'}}/>
                </Link>
                <Button color="inherit" onClick={signout}>
                    Logout
                </Button>
            </Breadcrumbs>
        </div>
    );
}

function PrivateRouteWrapper({ children, ...rest }) {
    let { user, isLoading } = useAuth();
    if (isLoading) {
        return <LinearProgress />
    }
    return (
        <Route
            {...rest}
            render={({ location }) =>
                user ? (
                    <>
                        <BasicNavigation/>
                        { children }
                    </>

                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRouteWrapper;
