import {Box, Button, TextField, Typography} from "@mui/material";
import React from "react";

const style = {
    top: '50%',
    left: '50%',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ConfirmDialogUser = ({deleteUser, handleClose}) => {

    return <Box sx={style}>
        <Typography>
            Are you sure that you want to delete this user?
        </Typography>
        <div style={{"display": "flex", "justifyContent": "flex-end"}}>
            <Button variant="contained" style={{margin: '5px'}} onClick={() => deleteUser()}>Yes</Button>
            <Button variant="outlined" style={{margin: '5px'}} onClick={() => handleClose()}>Cancel</Button>
        </div>
    </Box>
}

export default ConfirmDialogUser;
