import React, { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'

const useStyles = makeStyles({
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    }
});

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 250,
        editable: false,
        flex:0.5
    },
    {
        field: 'activations',
        headerName: '# of Activations',
        width: 250,
        editable: false,
        flex: 0.5,
        valueGetter: (params) => {
            return params.row.issues ? params.row.issues.length : 0
        }
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 250,
        editable: false,
        flex:0.5,
        valueGetter: (params) => {
            return params.row.trial === true ? 'trial' : 'paid';
        }
    },
    {
        field: 'expires',
        headerName: 'Expires',
        description: 'This column has a value getter and is not sortable.',
        editable: false,
        width: 250,
        flex:0.5,
        type: 'date',
        valueFormatter: ({value}) => new Date(value).toLocaleDateString()
    },
    { field: '_id', hide: true }
];

const LicenseList = (props) => {
    const { data } = props;
    const [selectionModel, setSelectionModel] = React.useState(data[0]._id);
    const classes = useStyles();

    useEffect(() => {
        props.onLicenseSelect(data[0]._id)
    }, []);

    return (<div style={{height: 400, width: "110%"}}>
        <DataGrid getRowId={(r) => r._id}
                  className={classes.root}
                  rows={data}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  selectionModel={selectionModel}
                  onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                  }}
                  disableMultipleSelection={true}
                  onRowClick={(rowData) => {
                      props.onLicenseSelect(rowData.id)
                  }}
        >
        </DataGrid>
    </div>)
}

export default LicenseList;
