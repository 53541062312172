import { useAuth } from "../hooks/useAuth";
import {
    Route,
    Redirect
} from "react-router-dom";

import LinearProgress from '@mui/material/LinearProgress';

function PublicRouteWrapper({ children, ...rest }) {
    let { user, isLoading } = useAuth();

    if (isLoading) {
        return <LinearProgress />
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                user ? (<Redirect to="/user"/>) : (children)
            }
        />
    );
}

export default PublicRouteWrapper;
