import {Box, Button, Dialog, TextField} from "@mui/material";
import React, { useState, useEffect} from 'react';
import API from "../api";
import * as yup from "yup";
import { useFormik } from "formik";

const style = {
    top: '50%',
    left: '50%',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ForgotPasswordForm = ({handleClose, email}) => {
    const validationSchema = yup.object({
        email: yup.string()
            .required("Email is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const { email } = values;
                const reqBody = {
                    email
                };
                await API.post('/forgotPassword', reqBody, { withCredentials: true });
                setSubmitting(false);
                handleClose(email);
            } catch (e) {
                setErrors({'general': e.data || e.message});
            }
        }
    });

    return (

        <Box sx={style}>
            <form onSubmit={formik.handleSubmit} style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                padding: '20px'
            }}>
                <TextField
                    name="email"
                    label={"Your Email"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                />
                {formik.errors.general && <span style={{color: 'red', display: 'block', margin: '10px 0 10px 0'}}>{formik.errors.general}</span>}
                <div style={{"display": "flex", "justifyContent": "flex-end"}}>
                    <Button type="submit" variant="contained" style={{margin: '5px'}}>Submit</Button>
                    <Button variant="outlined" style={{margin: '5px'}} onClick={() => handleClose()}>Cancel</Button>
                </div>
            </form>
        </Box>
    );
}

export default ForgotPasswordForm;
