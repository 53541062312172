import {Box, Button, Dialog, TextField, Typography} from "@mui/material";
// import {Controller, useForm} from "react-hook-form";
import React, { useState, useEffect} from 'react';
import API from "../api";
import "../pages/styles.css";


import * as yup from "yup";
import { useFormik } from 'formik';

const style = {
    // position: 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


const AddUserForm = ({handleClose, handleUpdateUserDetais, licenseId}) => {
    const validationSchema = yup.object({
        password: yup.string()
            .min(8, 'Should be minimum 8 ')
            .max(40, 'Should be maximum 40')
            .required("Password is required")
            .matches(/^.*(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1})/, 'Should include at least 1 digit, 1 uppercase, 1 lowercase'),
        email: yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        firstName: yup.string()
            .required("First name is required"),
        lastName: yup.string()
            .required('Last name is required')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            firstName: '',
            lastName: ''
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const { firstName, lastName, email, password } = values;
                const reqBody = {
                    firstName,
                    lastName,
                    email,
                    password,
                    licenseId
                };
                await API.post('/addUser', reqBody, { withCredentials: true });
                setSubmitting(false);
                handleClose();
                handleUpdateUserDetais();
            } catch (e) {
                setErrors({'general': e.data || e.message});
            }
        }
    });

    return (
        <Box sx={style}>
            <form onSubmit={formik.handleSubmit} style={{
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                padding: '20px'
            }}>
                <Typography variant='h6'>
                    Add User to License
                </Typography>
                <TextField
                    name="firstName"
                    label={"First Name"}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    margin="normal"
                />
                <TextField
                    name="lastName"
                    label={"Last Name"}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    margin="normal"
                />
                <TextField
                    name="email"
                    label={"Email"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    margin="normal"
                />
                <TextField
                    name="password"
                    label={"Password"}
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    margin="normal"
                />

                {formik.errors.general && <span style={{color: 'red', display: 'block', margin: '10px 0 10px 0'}}>{formik.errors.general}</span>}
                <div style={{"display": "flex", "justifyContent": "flex-end"}}>
                    <Button type="submit" variant="contained" style={{margin: '5px'}} >Add</Button>
                    <Button variant="outlined" style={{margin: '5px'}} onClick={() => handleClose()}>Cancel</Button>
                </div>
            </form>



            {/*<form onSubmit={handleSubmit(addUserToLicense)}*/}
            {/*      style={{*/}
            {/*          display: 'flex',*/}
            {/*          flexDirection: 'column',*/}
            {/*          background: 'white',*/}
            {/*          padding: '20px'*/}
            {/*      }}>*/}
            {/*    <Controller control={control} name="firstName" render={({field: {onChange, value}, fieldState: {error}}) => (*/}
            {/*        <TextField*/}
            {/*            required*/}
            {/*            label="First Name"*/}
            {/*            value={value}*/}
            {/*            onChange={onChange}*/}
            {/*            error={!!error}*/}
            {/*            margin="normal"*/}
            {/*            helperText={error ? error.message : null}*/}
            {/*        />*/}
            {/*    )}/>*/}
            {/*    <Controller control={control} name="lastName" render={({field: {onChange, value}, fieldState: {error}}) => (*/}
            {/*        <TextField*/}
            {/*            required*/}
            {/*            label="Last Name"*/}
            {/*            value={value}*/}
            {/*            onChange={onChange}*/}
            {/*            error={!!error}*/}
            {/*            margin="normal"*/}
            {/*            helperText={error ? error.message : null}*/}
            {/*        />*/}
            {/*    )}/>*/}
            {/*    <Controller control={control} name="email" render={({field: {onChange, value}, fieldState: {error}}) => (*/}
            {/*        <TextField*/}
            {/*            required*/}
            {/*            label="Email"*/}
            {/*            value={value}*/}
            {/*            onChange={onChange}*/}
            {/*            error={!!error}*/}
            {/*            margin="normal"*/}
            {/*            helperText={error ? error.message : null}*/}
            {/*        />*/}
            {/*    )}/>*/}
            {/*    <Controller control={control} name="password" render={({field: {onChange, value}, fieldState: {error}}) => (*/}
            {/*        <TextField*/}
            {/*            required*/}
            {/*            label="Password"*/}
            {/*            value={value}*/}
            {/*            onChange={onChange}*/}
            {/*            error={!!error}*/}
            {/*            margin="normal"*/}
            {/*            helperText={error ? error.message : null}*/}
            {/*        />*/}
            {/*    )}/>*/}
            {/*    <div style={{"display": "flex", "justifyContent": "flex-end"}}>*/}
            {/*        <Button type="submit" variant="contained" style={{margin: '5px'}} >Add User</Button>*/}
            {/*        <Button variant="outlined" style={{margin: '5px'}} onClick={() => handleClose()}>Cancel</Button>*/}
            {/*    </div>*/}

            {/*</form>*/}
        </Box>
    );
}

export default AddUserForm;
