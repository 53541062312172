import axios from 'axios';

const instance = axios.create({
    baseURL: '/'
});


instance.interceptors.response.use((response) => response, (error) => {
    const { response } = error;
    return Promise.reject(response || error);
});

export default instance;