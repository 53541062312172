import React, {Component, useState, useEffect} from "react";
import {Paper, TextField, Box, Grid, Dialog, Button, Snackbar, LinearProgress, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {DataGrid} from '@mui/x-data-grid'
import AddUserForm from '../components/AddUserForm';
import LicenseList from "../components/LicenseLIst";
import ChangePasswordForm from '../components/ChangePasswordForm';
import "./styles.css";
import API from '../api';
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography} from "@material-ui/core";
import instance from "../api";
import { useAuth } from "../hooks/useAuth";
import jwtDecode from "jwt-decode";
import OfflineKey from "../components/OfflineKey";
import ConfirmDialog from "../components/ConfirmDialog";
import ConfirmDialogUser from "../components/ConfirmDialogUser";
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    toolbar: {
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    toolbarActions: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    content: {
        margin: theme.spacing(2),
        padding: theme.spacing(2)
    },
    contentText: {
        margin: theme.spacing(1)
    }
}));

function UserInfo() {
    let { redirectOnUnauthorized, user } = useAuth();
    const [data, setData] = useState(null);
    const [licenseUsers, setLicenseUsers] = useState(null);
    const [licenseId, setLicenseId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [newUserFormOpen, setNewUserFormOpen] = useState(false);
    const [generateOfflineKeyOpen, setGenerateOfflineKeyOpen] = useState(false);
    const [updatePasswordFormOpen, setUpdatePasswordFormOpen] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogUserOpen, setConfirmDialogUserOpen] = useState(false);
    const classes = useStyles();
    const [userDetails, setUserDetails] = useState(null);

    API.interceptors.response.use((response) => response, (error) => {
        if (error && error.status === 401 && error.data ==='Invalid Token') {
            redirectOnUnauthorized();
        }
        return Promise.reject(error);
    });

    async function fetchData() {
        try {
            setIsLoading(true);
            const response = await API.get('/userDetails?accountId=' + jwtDecode(user).accountId, {withCredentials: true});

            const {data} = response;
            const {firstName, lastName, email, isOwner} = jwtDecode(user);
            data.first = firstName;
            data.last = lastName;
            data.email = email;
            data.isOwner = isOwner
            const selectedLicenseId = data.licenses[0]._id;
            setLicenseId(selectedLicenseId)
            setData(data);
            setIsLoading(false);

        } catch (e) {
            setHasError(e.data);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const closeUpdatePasswordForm = () => {
        setUpdatePasswordFormOpen(false);
    }

    const closeGenerateOfflineKeyForm = () => {
        setGenerateOfflineKeyOpen(false);
    }

    const closeNewUserForm = () => {
        setNewUserFormOpen(false);
    }

    const closeConfirmDialog = () => {
        setConfirmDialogOpen(false)
    }

    const closeConfirmDialogUser = () => {
        setConfirmDialogUserOpen(false)
    }

    const updateUserDetails = ()=> {
        setData(null);
        fetchData();
    }

    const resetLicense = async () => {
        try {
            await API.post("/resetActivations", { licenseId }, {withCredentials: true})
            fetchData();
            displaySnackbar();
            closeConfirmDialog();

        } catch (e) {
            setHasError(e.data);
        }
    }

    const onLicenseSelect = async (licenseId) => {
        const { data } = await API.get('/getLicenseUsers?licenseId=' + licenseId);
        setLicenseId(licenseId);
        setLicenseUsers(data);
    }

    const displaySnackbar = () => {
        setSnackBarOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    const deleteUser = async () => {
        try {
            await API.post("/deleteUser", userDetails)
            setData(null);
            fetchData();
            closeConfirmDialogUser();
        } catch (e) {
            setHasError(e.data);
        }
    }

    const actions = [
        <Button
            label="Cancel"
            primary={true}
            // onTouchTap={handleClose}
        />,
        <Button
            label="Submit"
            primary={true}
            keyboardFocused={true}
            // onTouchTap={handleClose}
        />,
    ];

    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            editable: false,
            flex:0.5

        },
        {
            field: 'fname',
            headerName: 'First Name',
            width: 250,
            editable: false,
            flex:0.5
        },
        {
            field: 'lname',
            headerName: 'Last Name',
            description: 'This column has a value getter and is not sortable.',
            editable: false,
            width: 250,
            flex:0.5

        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                const onClick = async () => {
                    setConfirmDialogUserOpen(true);
                    const userData = {
                        email: params.row.email,
                        fname: params.row.fname,
                        lname: params.row.lname,
                        userId: params.row._id
                    }
                    setUserDetails(userData);
                }
                if (data.email !== params.row.email) {
                    return (
                        <IconButton
                            aria-label="delete"
                            onClick={onClick}
                        >
                        <DeleteIcon />
                        </IconButton>)
                }
            }
        },
        { field: '_id', hide: true }
    ];

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Paper className={classes.content}>
                {data ?
                    <>

                        <Grid container spacing={24}>
                            <Grid item xs={6}>
                                <Typography variant="h6" component="h2" color="primary">
                                    My Licenses
                                </Typography>
                                <div style={{paddingTop: '20px'}}>
                                    { data && data.licenses && data.licenses.length > 0 ? <LicenseList data={data.licenses} onLicenseSelect={onLicenseSelect}></LicenseList> : <div>There is no license associated with this user</div> }
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6" component="h2" color="primary">
                                    My Info
                                </Typography>
                                <div className={classes.toolbar}>
                                <Typography className={classes.contentText} color="primary">
                                    First Name
                                </Typography>
                                <Typography className={classes.contentText} >
                                    {data.first ? data.first : 'First name is not set'}
                                </Typography>
                                </div>
                                <div className={classes.toolbar}>
                                    <Typography className={classes.contentText} color="primary">
                                        Last Name
                                    </Typography>
                                    <Typography className={classes.contentText}>
                                        {data.last ? data.last : 'Last name is not set'}
                                    </Typography>
                                </div>
                                <div className={classes.toolbar}>
                                    <Typography className={classes.contentText} color="primary">
                                        Email
                                    </Typography>
                                    <Typography className={classes.contentText}>
                                        {data.email ? data.email : 'Email is not set'}
                                    </Typography>
                                </div>

                            </Grid>
                        </Grid>

                        <Grid container spacing={24} style={{paddingTop: '20px', paddingBottom: '30px'}}>
                            <Grid item xs={6}>
                                <div style={{display: 'inline-block', position: 'absolute'}}>
                                    <Button variant="contained" style={{margin: '5px', display: 'inline-block'}} onClick={() =>
                                        setConfirmDialogOpen(true)
                                    }>Reset Activations</Button>
                                    <Button variant="contained" style={{margin: '5px', display: 'inline-block'}} onClick={() => {
                                        setNewUserFormOpen(true)
                                    }}>Add User To License</Button>
                                    <Button variant="contained" style={{margin: '5px', display: 'inline-block'}} onClick={() => {
                                        setGenerateOfflineKeyOpen(true)
                                    }}>Generate Offline Key</Button>
                                </div>

                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="outlined" style={{margin: '5px'}} onClick={() => {
                                    setUpdatePasswordFormOpen(true)
                                }}>Change Password</Button>
                            </Grid>
                        </Grid>
                        {(data.isOwner && licenseUsers !== null) ?  <div style={{height: 400, width: "100%"}}>
                            <DataGrid
                                getRowId={(r) => r._id}
                                rows={licenseUsers}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                            />
                        </div> : <div></div>}
                        <Dialog
                            actions={actions}
                            open={newUserFormOpen}
                            onClose={(event, reason) => {
                                if (reason === 'backdropClick') {
                                    closeNewUserForm();
                                }
                            }}
                        >
                            <AddUserForm handleClose={closeNewUserForm} handleUpdateUserDetais={updateUserDetails} licenseId={licenseId} />
                        </Dialog>
                        <Dialog
                            title="Updating password"
                            actions={actions}
                            open={updatePasswordFormOpen}
                            onClose={(event, reason) => {
                                if (reason === 'backdropClick') {
                                    closeUpdatePasswordForm();
                                }
                            }}
                        >
                            <ChangePasswordForm handleClose={closeUpdatePasswordForm}/>
                        </Dialog>
                        <Dialog open={confirmDialogOpen} title="Confirm Dialog" onClose={(event, reason) => {
                            if (reason === 'backdropClick') {
                                closeConfirmDialog();
                            }
                        }
                        } >
                            <ConfirmDialog handleClose={closeConfirmDialog} resetActivations={resetLicense}/>
                        </Dialog>
                        <Dialog open={generateOfflineKeyOpen} title="Generate Offline Key" onClose={(event, reason) => {
                                if (reason === 'backdropClick') {
                                    closeGenerateOfflineKeyForm();
                                }
                            }
                        } >
                            <OfflineKey closeDialog={closeGenerateOfflineKeyForm} licenseId={licenseId} />

                        </Dialog>
                        <Dialog open={confirmDialogUserOpen} title="Confirm Dialog" onClose={(event, reason) => {
                            if (reason === 'backdropClick') {
                                closeConfirmDialogUser();
                            }
                        }
                        } >
                            <ConfirmDialogUser handleClose={closeConfirmDialogUser} deleteUser={deleteUser}/>
                        </Dialog>
                        <Snackbar
                            open={snackBarOpen}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message="License activations have been successfully reset"
                            action={action}
                            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        />
                    </>
                    : (hasError ?
                        <div style={{color: 'red', margin: '10px 0 10px 0'}}> { hasError }</div>
                        : isLoading ?
                        <LinearProgress/>
                            : <></>)
                }
            </Paper>
        </div>
    );
};

export default UserInfo;
