import React, { useState, useEffect, useContext, createContext } from "react";
import API from "../api";
import axios from "axios";
import { useCookies } from 'react-cookie';
import history from '../utils/history';

const authContext = createContext();
// Provider component that wraps your app and makes auth object
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [cookies] = useCookies(['jwt-token']);
    const signin = async (email, password) => {
        let response = await API.post('/login', { email, password }, { withCredentials: true } );
        setUser(response.data.jwtToken);
        setLoading(false);
        return response.data;
    };
    const signout = async () => {
        await API.post('/logout', {}, { withCredentials: true } );
        setLoading(false);
        setUser(null);
    };
    const redirectOnUnauthorized = async () => {
        history.push('/');
        setLoading(false);
        setUser(null)
    };
    useEffect(() => {
        //todo call isLoggedIn server-side function to validate saved token
        const fetchData = ()=> {
            if (cookies['jwt-token']) {
                setUser(cookies['jwt-token']);
            }
            setLoading(false);
        }
        fetchData();
    }, []);
    return {
        user,
        signin,
        signout,
        isLoading,
        redirectOnUnauthorized,
    };
}
